.common-bgm {
  background-size: cover!important;
  background: url("../../assets/newIndex.png") no-repeat;
}
.innerMongolia-bgm {
  background: url("../../assets/loginInnerMongolia/newIndex.png") no-repeat;
}
#pageContol table {
  width: 100% !important;
}
#pageContol .framePage-body {
  background-size: 100% 100%;
}
#pageContol .newIndex {
  width: 100%;
  height: 100%;
  background-size: 100%;
  position: relative;
}
#pageContol .newIndex .box-card {
  width: 30%;
  min-width: 450px;
  background: #5c6be8;
  box-shadow: 0px 5px 24px 0px rgba(92, 92, 92, 0.35);
  border-radius: 10px;
  position: absolute;
  right: 8%;
}
#pageContol .newIndex .box-card .el-card__body {
  height: 100%;
}
#pageContol .newIndex .box-card .el-card__body .announcement {
  margin: 0 auto;
  margin-top: 20px;
  background: #ffffff;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
#pageContol .newIndex .box-card .el-card__body .announcement .el-table {
  border-radius: 10px;
  height: 100%;
}
#pageContol .newIndex .box-card .el-card__body .announcement .el-table .el-table__body-wrapper {
  height: 100% !important;
}
.tittleIndex {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.theText span {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.incontent {
  width: 400px;
}
